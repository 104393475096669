import Button from '@gameonsports/components/cjs/Button'
import { Stack } from '@gameonsports/components/cjs/StackV2'
import { Text } from '@gameonsports/components/cjs/TextV3'
import { useLocation } from '@reach/router'
import { QRCodeSVG } from 'qrcode.react'
import React, { useContext, useMemo, useState } from 'react'
import styled from 'styled-components'
import { appVariantConfigs } from '../../constants/appVariantConfigs'
import ThemeContext from '../../contexts/ThemeContext/ThemeContext'
import { useAppFooterBannerQuery } from '../../generated/graphql'
import { getMobileOperatingSystem } from '../../utils/device'
import { getBasePath } from '../../utils/route'
import { isSSR } from '../../utils/ssr'
import { track } from '../../utils/track'

const APP_FOOTER_BANNER_CLOSED_APPS_KEY = 'appFooterBannerClosedApps'

const getClosedApps = () => {
  if (isSSR) return []
  const storage = sessionStorage.getItem(APP_FOOTER_BANNER_CLOSED_APPS_KEY)
  return storage?.split(',') ?? []
}

const setClosedApp = (closedApp: string) => {
  if (isSSR) return
  const closedApps = getClosedApps()
  sessionStorage.setItem(
    APP_FOOTER_BANNER_CLOSED_APPS_KEY,
    closedApps.concat(closedApp).join(','),
  )
}

export const AppFooterBanner = ({ tenant }: { tenant?: string }) => {
  const [open, setOpen] = useState(false)
  const closedApps = useMemo(getClosedApps, [])
  const { hideFooterBanner } = useContext(ThemeContext)
  const location = useLocation()

  const os = getMobileOperatingSystem()

  const { data } = useAppFooterBannerQuery({
    context: tenant ? { headers: { tenant } } : undefined,
    skip: isSSR || hideFooterBanner,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const appSlug = data.tenantConfiguration?.outswingConfiguration?.value
      if (appSlug && !closedApps.includes(appSlug)) {
        setOpen(true)
      }
    },
  })

  const appVariant = Object.values(appVariantConfigs).find(
    a => a?.slug === data?.tenantConfiguration?.outswingConfiguration?.value,
  )

  if (hideFooterBanner || !appVariant || !open) return null

  const link =
    os === 'iOS'
      ? appVariant?.urls.appleAppStore
      : os === 'Android'
      ? appVariant?.urls?.googlePlayStore
      : undefined

  return (
    <Container>
      <Stack padding="m" direction="row" gap="m" alignItems="center">
        <Button
          aria-label="Close App Banner"
          icon="cross"
          variant="text"
          evenPadding
          iconSize="12"
          style={{ padding: 6 }}
          onClick={() => {
            setOpen(false)
            setClosedApp(appVariant.slug)
          }}
        />
        <IconImage src={appVariant.assets.icon} alt={appVariant.name} />
        <Stack style={{ flex: 1 }}>
          <Text size="16" weight="700">
            {appVariant.name} App
          </Text>
          {link && (
            <Text size="12" weight="400">
              Follow community {appVariant.sportAlt}
            </Text>
          )}
          {!link && (
            <Text size="12" weight="400">
              Scan the QR code with your device's camera to follow community{' '}
              {appVariant.sportAlt}.
            </Text>
          )}
        </Stack>
        {link && (
          <Button
            as="a"
            href={link}
            icon="right-arrow-thin"
            iconPosition="right"
            target="_blank"
            rel="noopener noreferrer"
            size="small"
            onClick={() =>
              track({ event: `click_app_footer_banner_${appVariant.slug}` })
            }
          >
            Go
          </Button>
        )}
        {!link && (
          <QRCodeSVG
            data-testid="app-store-qr-code"
            value={`${location.origin}/${getBasePath()}/app-store?slug=${
              appVariant.slug
            }`}
            width="3.375rem"
            height="3.375rem"
          />
        )}
      </Stack>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.white400};
  border-top: solid 1px ${({ theme }) => theme.colors.lightGrey400};
`

const IconImage = styled.img`
  height: 3.375rem;
`
